body {
  margin: 0;
  font-family: "Open Sans Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Open+Sans:wght@300&display=swap");

:root {
  --bg-color: #40925d;
  --alt-bg-color: #69520d;
  --font-color: #ffffff;
  --alt-font-color: #222222;
  --primary-color: #1a1a1a;
  --btn-bg-color: #526c20;
  --secondary-color: #be9e44;
}
.swiper {
  width: 240px;
  height: 320px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
